<template>
    <GlobalLoader></GlobalLoader>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>
<script setup lang="ts">
import GlobalLoader from './components/GlobalLoader.vue'
import { useAuthStore } from './store/auth'
import { variantConfig } from './config/variant'
import constants from './config/constants'

const AuthStore = useAuthStore()
const config = useRuntimeConfig()

onMounted(() => {
    if (AuthStore.IsAuth && config.public.variant === 'megadescuentos') {
        setTimeout(() => {
            AuthStore.updateActivitiesCount()

            setInterval(() => {
                AuthStore.updateActivitiesCount()
            }, 60000)
        }, 5000)
    }
})

const variant = config.public.variant as 'megadescuentos' | 'jdescuentos' | 'bchollos'

let analyticsHead = {
    script: [],
    noscript: [],
} as
    | {
          script: []
          noscript: []
      }
    | any

const allowAnalyticsCookie = useCookie(constants.cookies.cookies_bot_config.marketing)

if (
    (variant === 'bchollos' && allowAnalyticsCookie.value) ||
    variant === 'megadescuentos' ||
    variant === 'jdescuentos'
) {
    analyticsHead = {
        script: [
            ...(variantConfig.gtmId[variant]
                ? [
                      {
                          hid: 'gtag-src-head',
                          type: 'text/javascript',
                          async: true,
                          defer: true,
                          src: 'https://www.googletagmanager.com/gtag/js?id=' + variantConfig.gtmId[variant],
                      },
                      {
                          hid: 'gtag-head',
                          type: 'text/javascript',
                          charset: 'utf-8',
                          body: false,
                          innerHTML: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${variantConfig.gtmId[variant]}'); `,
                      },
                  ]
                : []),

            ...(variantConfig.GTMKeys[variant]
                ? [
                      {
                          key: 'gtm-head',
                          type: 'text/javascript',
                          innerHTML: `setTimeout(function(){
                      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.type='text/javascript';j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${variantConfig.GTMKeys[variant]}');
                  }, 6000)`,
                      },
                  ]
                : []),
            ...(!!variantConfig.digidip[variant]
                ? [
                      {
                          key: 'digidip-head',
                          type: 'text/javascript',
                          innerHTML: `(function(){
                      var loc = window.location.href;
                      var dd = document.createElement('script');
                      dd.type = 'text/javascript'; dd.src = 'https://static.digidip.net/${variantConfig.digidip[variant]}.js?loc=' + loc;
                      var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(dd, s);
                    })()`,
                      },
                  ]
                : []),
        ],
        noscript: variantConfig.GTMKeys[variant]
            ? [
                  {
                      hid: 'gtm-body',
                      innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${variantConfig.GTMKeys[variant]}" height="0" width="0" style="display: none; visibility: hidden"></iframe>`,
                      body: true,
                  },
              ]
            : [],
    }
}

useHead({
    htmlAttrs: {
        lang: variantConfig.siteLangCode[variant].code,
    },
    link: [
        {
            rel: 'icon',
            href: `/favicon/${variant}.ico`,
            type: 'image/x-icon',
        },
        {
            rel: 'preload',
            href: '/assets/fonts/Poppins-Light.ttf',
            crossorigin: 'anonymous',
            as: 'font',
        },
        {
            rel: 'preload',
            href: '/assets/fonts/Poppins-Regular.ttf',
            crossorigin: 'anonymous',
            as: 'font',
        },
        {
            rel: 'preload',
            href: '/assets/fonts/Poppins-Medium.ttf',
            crossorigin: 'anonymous',
            as: 'font',
        },
        {
            rel: 'preload',
            href: '/assets/fonts/Poppins-SemiBold.ttf',
            crossorigin: 'anonymous',
            as: 'font',
        },
        {
            rel: 'preload',
            href: '/assets/fonts/Poppins-Bold.ttf',
            crossorigin: 'anonymous',
            as: 'font',
        },
    ],
    script: [
        {
            key: 'fa-head',
            type: 'text/javascript',
            async: true,
            defer: true,
            innerHTML: `setTimeout(function() {
      var script = document.createElement("script")
      script.type = "text/javascript"
      script.src = "/js/fontawesome.js"
      script.async = true
      script.defer = true
      document.head.append(script)
                  }, 6000)`,
        },
        ...analyticsHead.script,
        ...(variant === 'bchollos' && variantConfig.cookieBot[variant]
            ? [
                  {
                      src: 'https://consent.cookiebot.com/uc.js?cbid=' + variantConfig.cookieBot[variant],
                      type: 'text/javascript',
                  },
              ]
            : []),
    ],
    noscript: analyticsHead.noscript,
    meta: variantConfig.metaTags(variant),
})
</script>
