import { default as discount_45maskj9HVw2dGOTMeta } from "~/views/discount-mask.vue?macro=true";
import { default as store_45maskrrOfhZN0pkMeta } from "~/views/store-mask.vue?macro=true";
import { default as store_45landing6Fs2zRsHqVMeta } from "~/views/store-landing.vue?macro=true";
import { default as index3OD2unLZ5IMeta } from "~/views/index.vue?macro=true";
import { default as store_45bridgeRyrhTqaIb2Meta } from "~/views/store-bridge.vue?macro=true";
import { default as category_45bridgepUYmiO2rTXMeta } from "~/views/category-bridge.vue?macro=true";
import { default as discount_45detailsAu0njxb3NgMeta } from "~/views/discount-details.vue?macro=true";
import { default as acerca_45de_45megadescuentosgHbTi8yHk2Meta } from "~/views/acerca-de-megadescuentos.vue?macro=true";
import { default as aviso_45legal27GreimsDMMeta } from "~/views/aviso-legal.vue?macro=true";
import { default as ayuda6cYlYHtpPuMeta } from "~/views/ayuda.vue?macro=true";
import { default as indexPUvMN4GjS1Meta } from "~/views/blog/index.vue?macro=true";
import { default as contactouEjPCNMSn5Meta } from "~/views/contacto.vue?macro=true";
import { default as cookiesZdG9x55enXMeta } from "~/views/cookies.vue?macro=true";
import { default as cuponescYUW8ZiMjPMeta } from "~/views/cupones.vue?macro=true";
import { default as indexrC7BL1lmx3Meta } from "~/views/eventos/index.vue?macro=true";
import { default as expirados2cVA3uut0qMeta } from "~/views/expirados.vue?macro=true";
import { default as faqknATEkiae7Meta } from "~/views/faq.vue?macro=true";
import { default as extensionXtPi7lhYhcMeta } from "~/views/extension.vue?macro=true";
import { default as extension_45desinstaladaLj71lcCfqlMeta } from "~/views/extension-desinstalada.vue?macro=true";
import { default as indexepgiXoczlTMeta } from "~/views/foros/index.vue?macro=true";
import { default as cambiar_45contrasenaSBmBITDIAiMeta } from "~/views/cambiar-contrasena.vue?macro=true";
import { default as indexcCXr3WFVrjMeta } from "~/views/compartir/index.vue?macro=true";
import { default as contrasena_45actualizada_45exitosamentePmJ3nkPjKhMeta } from "~/views/contrasena-actualizada-exitosamente.vue?macro=true";
import { default as inicio_45sin_45contrasenaZGhs8O1mtzMeta } from "~/views/inicio-sin-contrasena.vue?macro=true";
import { default as loginENTaGq0TN7Meta } from "~/views/login.vue?macro=true";
import { default as nueva_45contrasenamQCUaOjNUMMeta } from "~/views/nueva-contrasena.vue?macro=true";
import { default as olvide_45mi_45contrasenatVMnovpcGRMeta } from "~/views/olvide-mi-contrasena.vue?macro=true";
import { default as registrochvWhxVJvRMeta } from "~/views/registro.vue?macro=true";
import { default as verificar_45cuentaDuaFMvaeZlMeta } from "~/views/verificar-cuenta.vue?macro=true";
import { default as indexabBTIOX1Q9Meta } from "~/views/dashboard/index.vue?macro=true";
import { default as _91user_939oonPanUJRMeta } from "~/views/dashboard/[user].vue?macro=true";
import { default as chatreZWaCTnISMeta } from "~/views/compartir/chat.vue?macro=true";
import { default as indexIRoKS7HatAMeta } from "~/views/compartir/cupon/index.vue?macro=true";
import { default as finalizado0BzlWrr2tDMeta } from "~/views/compartir/finalizado.vue?macro=true";
import { default as indexBeJitdu2owMeta } from "~/views/compartir/oferta/index.vue?macro=true";
import { default as crear3algxG5VAvMeta } from "~/views/compartir/cupon/crear.vue?macro=true";
import { default as crearZbQMIXIIdmMeta } from "~/views/compartir/oferta/crear.vue?macro=true";
import { default as _91slug_93v4BJVgWSJlMeta } from "~/views/foros/[slug].vue?macro=true";
import { default as mensajeriat32FNxbtWDMeta } from "~/views/mensajeria.vue?macro=true";
import { default as notificacionesibVdBV4j0xMeta } from "~/views/notificaciones.vue?macro=true";
import { default as indexBxQ1hBk8ccMeta } from "~/views/novedades/index.vue?macro=true";
import { default as _91slug_93muY0TE8fNMMeta } from "~/views/novedades/[slug].vue?macro=true";
import { default as subscribeQTTDBqfvVMMeta } from "~/views/subscribe.vue?macro=true";
import { default as unsubscribeGldhVNMFKoMeta } from "~/views/unsubscribe.vue?macro=true";
import { default as gratisMnPRtxOIQDMeta } from "~/views/gratis.vue?macro=true";
import { default as ofertas1iv4IpaQurMeta } from "~/views/ofertas.vue?macro=true";
import { default as politicas_45de_45privacidadnm6yFCqQsmMeta } from "~/views/politicas-de-privacidad.vue?macro=true";
import { default as prensajve8ab1rGMMeta } from "~/views/prensa.vue?macro=true";
import { default as reglas_45de_45comunidadOV9dTNAqzDMeta } from "~/views/reglas-de-comunidad.vue?macro=true";
import { default as searchJXWnylL0IyMeta } from "~/views/search.vue?macro=true";
import { default as indexw4U5mpNMlrMeta } from "~/views/blog/[category]/index.vue?macro=true";
import { default as _91slug_93Rh3p8BZ1JvMeta } from "~/views/eventos/[slug].vue?macro=true";
import { default as _91article_930CtyZBH1FtMeta } from "~/views/blog/[category]/[article].vue?macro=true";
import { default as indexFNYRkGxMcmMeta } from "~/views/sitemap/index.vue?macro=true";
import { default as component_45stub1t2WxpUmsFMeta } from "/github/workspace/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub1t2WxpUmsF } from "/github/workspace/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "open-slug",
    path: "/open/:slug",
    meta: discount_45maskj9HVw2dGOTMeta || {},
    component: () => import("~/views/discount-mask.vue").then(m => m.default || m)
  },
  {
    name: "go-id",
    path: "/go/:id",
    component: () => import("~/views/discount-mask.vue").then(m => m.default || m)
  },
  {
    name: "go-marcas-id",
    path: "/go/marcas/:id",
    meta: store_45maskrrOfhZN0pkMeta || {},
    component: () => import("~/views/store-mask.vue").then(m => m.default || m)
  },
  {
    name: "landing-slug",
    path: "/landing/:slug",
    meta: store_45landing6Fs2zRsHqVMeta || {},
    component: () => import("~/views/store-landing.vue").then(m => m.default || m)
  },
  {
    name: "homepage-nuevos",
    path: "/nuevos",
    component: () => import("~/views/index.vue").then(m => m.default || m)
  },
  {
    name: "homepage-comentados",
    path: "/comentados",
    component: () => import("~/views/index.vue").then(m => m.default || m)
  },
  {
    name: "homepage",
    path: "/",
    component: () => import("~/views/index.vue").then(m => m.default || m)
  },
  {
    name: "marcas-slug",
    path: "/marcas/:slug",
    component: () => import("~/views/store-bridge.vue").then(m => m.default || m)
  },
  {
    name: "marcas",
    path: "/marcas",
    component: () => import("~/views/store-bridge.vue").then(m => m.default || m)
  },
  {
    name: "categorias-slug",
    path: "/categorias/:slug",
    component: () => import("~/views/category-bridge.vue").then(m => m.default || m)
  },
  {
    name: "categorias",
    path: "/categorias",
    component: () => import("~/views/category-bridge.vue").then(m => m.default || m)
  },
  {
    name: "cupones-slug",
    path: "/cupones/:slug",
    component: () => import("~/views/discount-details.vue").then(m => m.default || m)
  },
  {
    name: "ofertas-slug",
    path: "/ofertas/:slug",
    component: () => import("~/views/discount-details.vue").then(m => m.default || m)
  },
  {
    name: acerca_45de_45megadescuentosgHbTi8yHk2Meta?.name,
    path: "/acerca-de",
    component: () => import("~/views/acerca-de-megadescuentos.vue").then(m => m.default || m)
  },
  {
    name: aviso_45legal27GreimsDMMeta?.name,
    path: "/aviso-legal",
    component: () => import("~/views/aviso-legal.vue").then(m => m.default || m)
  },
  {
    name: ayuda6cYlYHtpPuMeta?.name,
    path: "/ayuda",
    component: () => import("~/views/ayuda.vue").then(m => m.default || m)
  },
  {
    name: indexPUvMN4GjS1Meta?.name,
    path: "/blog",
    component: () => import("~/views/blog/index.vue").then(m => m.default || m)
  },
  {
    name: category_45bridgepUYmiO2rTXMeta?.name,
    path: "/categorias",
    component: () => import("~/views/category-bridge.vue").then(m => m.default || m)
  },
  {
    name: contactouEjPCNMSn5Meta?.name,
    path: "/contacto",
    component: () => import("~/views/contacto.vue").then(m => m.default || m)
  },
  {
    name: cookiesZdG9x55enXMeta?.name,
    path: "/cookies",
    component: () => import("~/views/cookies.vue").then(m => m.default || m)
  },
  {
    name: cuponescYUW8ZiMjPMeta?.name,
    path: "/cupones",
    component: () => import("~/views/cupones.vue").then(m => m.default || m)
  },
  {
    name: indexrC7BL1lmx3Meta?.name,
    path: "/eventos",
    component: () => import("~/views/eventos/index.vue").then(m => m.default || m)
  },
  {
    name: expirados2cVA3uut0qMeta?.name,
    path: "/expirados",
    component: () => import("~/views/expirados.vue").then(m => m.default || m)
  },
  {
    name: faqknATEkiae7Meta?.name,
    path: "/faq",
    component: () => import("~/views/faq.vue").then(m => m.default || m)
  },
  {
    name: extensionXtPi7lhYhcMeta?.name,
    path: "/extension",
    component: () => import("~/views/extension.vue").then(m => m.default || m)
  },
  {
    name: extension_45desinstaladaLj71lcCfqlMeta?.name,
    path: "/extension-desinstalada",
    component: () => import("~/views/extension-desinstalada.vue").then(m => m.default || m)
  },
  {
    name: indexepgiXoczlTMeta?.name,
    path: "/foros",
    component: () => import("~/views/foros/index.vue").then(m => m.default || m)
  },
  {
    name: cambiar_45contrasenaSBmBITDIAiMeta?.name,
    path: "/cambiar-contrasena",
    meta: cambiar_45contrasenaSBmBITDIAiMeta || {},
    component: () => import("~/views/cambiar-contrasena.vue").then(m => m.default || m)
  },
  {
    name: indexcCXr3WFVrjMeta?.name,
    path: "/compartir",
    component: () => import("~/views/compartir/index.vue").then(m => m.default || m)
  },
  {
    name: contrasena_45actualizada_45exitosamentePmJ3nkPjKhMeta?.name,
    path: "/contrasena-actualizada-exitosamente",
    meta: contrasena_45actualizada_45exitosamentePmJ3nkPjKhMeta || {},
    component: () => import("~/views/contrasena-actualizada-exitosamente.vue").then(m => m.default || m)
  },
  {
    name: inicio_45sin_45contrasenaZGhs8O1mtzMeta?.name,
    path: "/inicio-sin-contrasena",
    meta: inicio_45sin_45contrasenaZGhs8O1mtzMeta || {},
    component: () => import("~/views/inicio-sin-contrasena.vue").then(m => m.default || m)
  },
  {
    name: loginENTaGq0TN7Meta?.name,
    path: "/login",
    meta: loginENTaGq0TN7Meta || {},
    component: () => import("~/views/login.vue").then(m => m.default || m)
  },
  {
    name: nueva_45contrasenamQCUaOjNUMMeta?.name,
    path: "/nueva-contrasena",
    meta: nueva_45contrasenamQCUaOjNUMMeta || {},
    component: () => import("~/views/nueva-contrasena.vue").then(m => m.default || m)
  },
  {
    name: olvide_45mi_45contrasenatVMnovpcGRMeta?.name,
    path: "/olvide-mi-contrasena",
    meta: olvide_45mi_45contrasenatVMnovpcGRMeta || {},
    component: () => import("~/views/olvide-mi-contrasena.vue").then(m => m.default || m)
  },
  {
    name: registrochvWhxVJvRMeta?.name,
    path: "/registro",
    meta: registrochvWhxVJvRMeta || {},
    component: () => import("~/views/registro.vue").then(m => m.default || m)
  },
  {
    name: verificar_45cuentaDuaFMvaeZlMeta?.name,
    path: "/verificar-cuenta",
    component: () => import("~/views/verificar-cuenta.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexabBTIOX1Q9Meta || {},
    component: () => import("~/views/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-user",
    path: "/dashboard/:user",
    component: () => import("~/views/dashboard/[user].vue").then(m => m.default || m)
  },
  {
    name: chatreZWaCTnISMeta?.name,
    path: "/compartir/chat",
    meta: chatreZWaCTnISMeta || {},
    component: () => import("~/views/compartir/chat.vue").then(m => m.default || m)
  },
  {
    name: indexIRoKS7HatAMeta?.name,
    path: "/compartir/cupon",
    meta: indexIRoKS7HatAMeta || {},
    component: () => import("~/views/compartir/cupon/index.vue").then(m => m.default || m)
  },
  {
    name: finalizado0BzlWrr2tDMeta?.name,
    path: "/compartir/finalizado",
    meta: finalizado0BzlWrr2tDMeta || {},
    component: () => import("~/views/compartir/finalizado.vue").then(m => m.default || m)
  },
  {
    name: indexBeJitdu2owMeta?.name,
    path: "/compartir/oferta",
    meta: indexBeJitdu2owMeta || {},
    component: () => import("~/views/compartir/oferta/index.vue").then(m => m.default || m)
  },
  {
    name: "compartir-cupon-crear",
    path: "/compartir/cupon/crear",
    meta: crear3algxG5VAvMeta || {},
    component: () => import("~/views/compartir/cupon/crear.vue").then(m => m.default || m)
  },
  {
    name: "compartir-oferta-crear",
    path: "/compartir/oferta/crear",
    meta: crearZbQMIXIIdmMeta || {},
    component: () => import("~/views/compartir/oferta/crear.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93v4BJVgWSJlMeta?.name,
    path: "/foros/:slug",
    component: () => import("~/views/foros/[slug].vue").then(m => m.default || m)
  },
  {
    name: mensajeriat32FNxbtWDMeta?.name,
    path: "/mensajeria",
    meta: mensajeriat32FNxbtWDMeta || {},
    component: () => import("~/views/mensajeria.vue").then(m => m.default || m)
  },
  {
    name: notificacionesibVdBV4j0xMeta?.name,
    path: "/notificaciones",
    meta: notificacionesibVdBV4j0xMeta || {},
    component: () => import("~/views/notificaciones.vue").then(m => m.default || m)
  },
  {
    name: indexBxQ1hBk8ccMeta?.name,
    path: "/novedades",
    component: () => import("~/views/novedades/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93muY0TE8fNMMeta?.name,
    path: "/novedades/:slug",
    component: () => import("~/views/novedades/[slug].vue").then(m => m.default || m)
  },
  {
    name: subscribeQTTDBqfvVMMeta?.name,
    path: "/subscribe",
    component: () => import("~/views/subscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribeGldhVNMFKoMeta?.name,
    path: "/unsubscribe",
    component: () => import("~/views/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: gratisMnPRtxOIQDMeta?.name,
    path: "/gratis",
    component: () => import("~/views/gratis.vue").then(m => m.default || m)
  },
  {
    name: store_45bridgeRyrhTqaIb2Meta?.name,
    path: "/marcas",
    component: () => import("~/views/store-bridge.vue").then(m => m.default || m)
  },
  {
    name: ofertas1iv4IpaQurMeta?.name,
    path: "/ofertas",
    component: () => import("~/views/ofertas.vue").then(m => m.default || m)
  },
  {
    name: politicas_45de_45privacidadnm6yFCqQsmMeta?.name,
    path: "/politicas-de-privacidad",
    component: () => import("~/views/politicas-de-privacidad.vue").then(m => m.default || m)
  },
  {
    name: prensajve8ab1rGMMeta?.name,
    path: "/prensa",
    component: () => import("~/views/prensa.vue").then(m => m.default || m)
  },
  {
    name: reglas_45de_45comunidadOV9dTNAqzDMeta?.name,
    path: "/reglas-de-comunidad",
    component: () => import("~/views/reglas-de-comunidad.vue").then(m => m.default || m)
  },
  {
    name: searchJXWnylL0IyMeta?.name,
    path: "/search",
    component: () => import("~/views/search.vue").then(m => m.default || m)
  },
  {
    name: indexw4U5mpNMlrMeta?.name,
    path: "/blog/:category",
    component: () => import("~/views/blog/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: category_45bridgepUYmiO2rTXMeta?.name,
    path: "/categorias/:slug",
    component: () => import("~/views/category-bridge.vue").then(m => m.default || m)
  },
  {
    name: discount_45detailsAu0njxb3NgMeta?.name,
    path: "/cupones/:slug",
    component: () => import("~/views/discount-details.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Rh3p8BZ1JvMeta?.name,
    path: "/eventos/:slug",
    component: () => import("~/views/eventos/[slug].vue").then(m => m.default || m)
  },
  {
    name: store_45bridgeRyrhTqaIb2Meta?.name,
    path: "/marcas/:slug",
    component: () => import("~/views/store-bridge.vue").then(m => m.default || m)
  },
  {
    name: discount_45detailsAu0njxb3NgMeta?.name,
    path: "/ofertas/:slug",
    component: () => import("~/views/discount-details.vue").then(m => m.default || m)
  },
  {
    name: _91article_930CtyZBH1FtMeta?.name,
    path: "/blog/:category/:article",
    component: () => import("~/views/blog/[category]/[article].vue").then(m => m.default || m)
  },
  {
    name: indexFNYRkGxMcmMeta?.name,
    path: "/sitemap",
    component: () => import("~/views/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub1t2WxpUmsF
  }
]